body {
  background-color: #f7f7f7;
  font-family: Arial, sans-serif;
  color: #333;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  margin-top: 15px;
  margin-bottom: 15px; 
}


.flex-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  margin: 0 auto;
  display: flexbox;
  max-width: 800px;
  padding: 20px;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 40px;
  box-shadow: 0 0 20px rgba(72, 48, 130, 1.5);
  text-align: center;
}



.public-ip {
  font-size: 24px; /* Ajuste o tamanho da fonte conforme necessário */
  font-weight: bold; /* Estilo da fonte */
  color: #333; /* Cor da fonte */
  margin: 20px 0; /* Espaçamento em torno do elemento */
}

h1 {
  color: #483082;
  text-align: center;
  margin-top: 2%;
}

h2 {
  color: #e8090c;
  text-align: center;
  margin-top: 10px;
}


h4 {
  color: #483082;
  text-align: center;
  margin-top: 20px;
  justify-content: center;
  align-items: center;
  padding-top: 25px;
  font-size: larger;
}

h5 {
  color: #483082;
  text-align: center;
  margin-top: 0px;
}

h6 {
  color: #483082;
  text-align: center;
  margin-top: 9%;
  position: bottom;
}

button {
  padding: 10px;
  background-color: #344d58;
  color: #fff;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: background-color 0.3s;
  align-items: center;
  text-align: center;
}

button:hover {
  background-color: #6babc8;
}


.flex-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /*height: 450px; /* Set a height for the container */
  top: 50%;
  left: 50%;
}

.center {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 20px;
}


.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

.flex-container {
  display: flex;
  justify-content: center;
  align-items: center;
}



@media (max-width: 600px) {
  .container {
    padding: 10px;
  }

  .flex-container {
    flex-direction: column;
  }

  .progress-bar {
    width: 90%;
  }
}

/* Progress bar styles */
.progress-bar {
  position: relative;
  width: 100%;
  height: 30px;
  background-color: #344d58;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  align-items: center; /* Center align items vertically */
  justify-content: center; /* Center align items horizontally */
}

.progress {
  height: 100%;
  background-color: #4caf50;
  transition: width 0.1s ease-in-out;
  display: flex;
  align-items: center; /* Center align items vertically */
  justify-content: center; /* Center align items horizontally */
  position: relative; /* Needed for absolute positioning of message */
}

.centered-message {
  position: absolute;
  width: 100%;
  text-align: center;
  line-height: 30px;
  color: #000;
  font-weight: bold;
}




